import { useEffect } from "react"
// import Layout from "../../components/Layout/Layout"
// import Container from "../../components/Layout/Container"
// import Intake from "../../components/Intake/Intake"
import { navigate } from "gatsby"

export default () => {
  useEffect(() => navigate("/intake/self"), [])
  return null
}

//   (
//   <Layout
//     title="Who are you requesting a mental health consult for?"
//     seoTitle="Request Teleconsult"
//     display={{ footer: false, helpCenterBanner: false }}
//   >
//     <Container isCentered>
//       <hr className="mt-0" />
//       <Intake />
//     </Container>
//   </Layout>
// )
